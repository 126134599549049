import _ from "lodash";
import * as fromAgent from './agent'
import { getAgentDetailRentObject, getAgentDetailSaleObject } from './agent'

export const getPropertyListLoading = state =>
  _.get(state, ["propertyList", "loading"], false);
export const getPropertyListItems = (state, route, page) =>
  _.get(state, ["propertyList", route, "items"], null);
export const getPropertyListPagination = (state, route) =>
  _.get(state, ["propertyList", route, "pagination"], null);
export const getPropertyDetailLoading = state =>
  _.get(state, ["propertyDetail", "loading"], false);
export const getPropertyDetailItems = (state, id) =>
  _.get(state, ["propertyDetail", id], null);
export const getPropertyToggleWishlistStatus = state => _.get(state,['toggleWishlistStatus'],false)

export const getPropertyPutPropertyDetailStatusLoading = state => _.get(state,['putPropertyDetailStatus','loading'],false)
export const getPropertyPutPropertyDetailStatusStatus = state => _.get(state,['putPropertyDetailStatus','status'],false)
export const getPropertyPostUserListingStatus = state => _.get(state, ["postUserPropertyListing", "status"], false);
export const getPropertyPostUserListingItem = state => _.get(state, ["postUserPropertyListing", "item"], null);
export const getPropertySearchProjectLoading = state => _.get(state, ["userPropertySearchProject", "loading"], false);
export const getPropertySearchProjectItems = state => _.get(state, ["userPropertySearchProject", "items"], []);
export const getPropertyPostUserListingLoading = state => _.get(state, ["postUserPropertyListing", "loading"], false);

//items
export const getPropertyDetailID = property => _.get(property, ["id"], null);
export const getPropertyDetailSlug = property => _.get(property, ["slug"], "");
export const getPropertyDetailName = property =>
  _.get(property, ["name"], null);
export const getPropertyDetailImageMediumSrc = property =>
  _.get(property, ["image", "medium", "src"], null);
export const getPropertyDetailImageHugeSrc = property =>
  _.get(property, ["image", "huge", "src"], null);
export const getPropertyDetailKindCode = property =>
  _.get(property, ["kind", "code"], null);
export const getPropertyDetailKindName = property =>
  _.get(property, ["kind", "name"], null);
export const getPropertyDetailTypeCode = property =>
  _.get(property, ["type", "code"], null);
export const getPropertyDetailTypeName = property =>
  _.get(property, ["type", "name"], null);
export const getPropertyDetailCategoryCode = property =>
  _.get(property, ["category", "code"], null);
export const getPropertyDetailCategoryName = property =>
  _.get(property, ["category", "name"], null);
export const getPropertyDetailTenureCode = property =>
  _.get(property, ["tenure", "code"], null);
export const getPropertyDetailTenureName = property =>
  _.get(property, ["tenure", "name"], null);
export const getPropertyDetailBuildStartValueText = property =>
  _.get(property, ["build", "start", "value", "text"], null);
export const getPropertyDetailBuildStartValue = property =>
  _.get(property, ["build", "start", "value", "value"], null);
export const getPropertyDetailBuildStartValueRelative = property =>
  _.get(property, ["build", "start", "value", "relative"], null);
export const getPropertyDetailBuildStartText = property =>
  _.get(property, ["build", "start", "text"], "");
export const getPropertyDetailBuildEndValueText = property =>
  _.get(property, ["build", "end", "value", "text"], null);
export const getPropertyDetailBuildEndValue = property =>
  _.get(property, ["build", "end", "value", "value"], null);
export const getPropertyDetailBuildEndValueRelative = property =>
  _.get(property, ["build", "end", "value", "relative"], null);
export const getPropertyDetailBuildEndText = property =>
  _.get(property, ["build", "end", "text"], "");
export const getPropertyDetailRoomBedText = property =>
  _.get(property, ["room", "bed", "text"], "");
export const getPropertyDetailRoomBathText = property =>
  _.get(property, ["room", "bath", "text"], "");
export const getPropertyDetailParkingPrimaryText = property =>
  _.get(property, ["parking", "primary", "text"], "");
export const getPropertyDetailSizeFloorText = property =>
  _.get(property, ["size", "floor", "text"], "");
export const getPropertyDetailSizeFloorValue = property =>
  _.get(property, ["size", "floor", "value"], "");
export const getPropertyDetailSizeLandText = property =>
  _.get(property, ["size", "land", "text"], "");
export const getPropertyDetailFurnishingCode = property =>
  _.get(property, ["furnishing", "code"], null);
export const getPropertyDetailFurnishingName = property =>
  _.get(property, ["furnishing", "name"], null);
export const getPropertyDetailFloorLevelCode = property =>
  _.get(property, ["floorLevel", "code"], null);
export const getPropertyDetailFloorLevelName = property =>
  _.get(property, ["floorLevel", "name"], null);
export const getPropertyDetailTagItems = property =>
  _.get(property, ["tag", "items"], []);
export const getPropertyDetailCountryName = property =>
  _.get(property, ["country", "name"], "");
export const getPropertyDetailCountryCode = property =>
  _.get(property, ["country", "code"], "");
export const getPropertyDetailStateName = property =>
  _.get(property, ["state", "name"], "");
export const getPropertyDetailStateCode = property =>
  _.get(property, ["state", "code"], "");
export const getPropertyDetailCityName = property =>
  _.get(property, ["city", "name"], "");
export const getPropertyDetailCityID = property =>
  _.get(property, ["city", "id"], "");
export const getPropertyDetailPostCode = property =>
  _.get(property, ["postcode"], "");
export const getPropertyDetailCoordinates = property =>
  _.get(property, ["coordinates"], null);
export const getPropertyDetailAttributeIsImageReady = property =>
  _.get(property, ["attribute", "isImageReady"], false);
export const getPropertyDetailSalePriceText = property =>
  _.get(property, ["sale", "price", "text"], "");
export const getPropertyDetailSalePriceValue = property =>
  _.get(property, ["sale", "price", "value"], 0);
export const getPropertyDetailPerSizeText = property =>
  _.get(property, ["perSize", "price", "text"], "");
export const getPropertyDetailPerSizeValue = property =>
  _.get(property, ["perSize", "price", "value"], 0);
export const getPropertyDetailSale = property =>
  _.get(property, ["sale"], null);
export const getPropertyDetailRent = property =>
  _.get(property, ["rent"], null);
export const getPropertyDetailContent = property =>
  _.get(property, ["content"], null);
export const getPropertyDetailMetaDescription = property =>
  _.get(property, ["_metadata", "description"], null);
export const getPropertyDetailRenVerify = property =>
  _.get(property, ["account", "_metadata", "isRenVerified"], null);
export const getPropertyDetailMobileVerify = property =>
  _.get(property, ["account", "_metadata", "isMobileVerified"], null);
export const getPropertyDetailImages = property =>
  _.get(property, ["images"], null);
export const getPropertyDetailFeatureItems = property =>
  _.get(property, ["feature", "items"], []);
export const getPropertyDetailAmenityItems = property =>
  _.get(property, ["amenity", "items"], []);
export const getPropertyDetailAccountName = property =>
  _.get(property, ["account", "name"], "");
export const getPropertyDetailAccountSlug = property =>
  _.get(property, ["account", "slug"], "");
export const getPropertyDetailRenNumber = property =>
  _.get(property, ["account", "licenseNumber"], "");
export const getPropertyDetailPublisherName = property =>
  _.get(property, ["publisher", "name"], "");
export const getPropertyDetailPublisherImageMedium = property =>
  _.get(property, ["publisher", "image", "medium", "src"], "");
export const getPropertyDetailPublisherRegistrationNumber = property =>
  _.get(property, ["publisher", "registrationNumber"], "");
export const getPropertyDetailAccountContactItems = property =>
  _.get(property, ["account", "contact", "items"], []);
export const getPropertyDetailAccountID = property =>
    _.get(property, ["account", "id"], null);
export const getPropertyDetailAccountImageSrc = property =>
  _.get(property, ["account", "image", 'medium',"src"], null);
export const getPropertyDetailPublishAtRelative = property =>
  _.get(property, ["publishedAt", "relative"], "");
export const getPropertyDetailPublishAtValue = property =>
  _.get(property, ["publishedAt", "value"], "");
export const getPropertyDetailSaleOrRentObject = property =>
  !_.isEmpty(getPropertyDetailSale(property))
    ? getPropertyDetailSale(property)
    : getPropertyDetailRent(property)
    ? getPropertyDetailRent(property)
    : null;

export const getPropertyIsSale = property =>
  !_.isEmpty(getPropertyDetailSale(property));
export const getPropertyIsRent = property =>
  !_.isEmpty(getPropertyDetailRent(property));
export const getPropertyDetailVideoUrl = property => _.get(property,['video','url'],"")
export const getPropertyDetailTiktokUrl = property => _.get(property,['tiktok','url'],"")
export const getPropertyPriceTypeCode = property =>
    _.get(property, ["priceType", "code"], "");
export const getPropertyPriceTypeName = property =>
    _.get(property, ["priceType", "name"], "");
export const getPropertyTitleTypeCode = property =>
    _.get(property, ["titleType", "code"], "");
export const getPropertyTitleTypeName = property =>
    _.get(property, ["titleType", "name"], "");
export const getPropertyRequiredFields = property =>
    _.get(property, ["requiredFields"], []);
export const getPropertyBumiLotTypeCode = property =>
    _.get(property, ["bumiLotType", "code"], "");
export const getPropertyBumiLotTypeName = property =>
    _.get(property, ["bumiLotType", "name"], "");
export const getPropertyCondition = property => _.get(property, ["condition"], "");
export const getPropertyCeilingHeight = property =>
    _.get(property, ["ceilingHeight"], "");
export const getPropertyFloorLoading = property => _.get(property, ["floorLoading"], "");
export const getPropertyParkingFees = property => _.get(property, ["parkingFees"], "");
export const getPropertyTotalCargoLift = property =>
    _.get(property, ["totalCargoLift"], "");
export const getPropertyMaximumLiftCapacity = property =>
    _.get(property, ["maximumLiftCapacity"], "");
export const getPropertyAirConditioning = property =>
    _.get(property, ["airConditioning"], "");
export const getPropertyAirconOperationHours = property =>
    _.get(property, ["airconOperationHours"], "");
export const getPropertyDetailAttributeIsWishlisted = property => _.get(property,['attribute','isWishlisted'],false)
export const getPropertyDetailHtmlContent = property => _.get(property,['htmlContent'],"")
export const getPropertyDetailNearbyListings = property => _.get(property,['nearbyListings'],"")
export const getPropertyDetailNearbySameListings = property => _.get(property,['nearbySameListings'],"")
export const getPropertyDetailNearbyPropertyListings = property => _.get(property,['nearbyPropertyListings'],"")
export const getPropertyDetailSameAgentListings = property => _.get(property,['sameAgentListings'],"")
export const getPropertyDetailAttributeForSales = property => _.get(property,['attribute','forSale'],false)
export const getPropertyDetailAttributeForRent = property => _.get(property,['attribute','forRent'],false)


//coordinate
export const getCoordinateLatitude = coordinate =>
  _.get(coordinate, ["latitude"], "");
export const getCoordinateLongitude = coordinate =>
  _.get(coordinate, ["longitude"], "");

//filterData
export const getFilterTypes = filter => _.get(filter, ["types"], []);
export const getFilterTenures = filter => _.get(filter, ["tenures"], []);
export const getFilterBedRooms = filter => _.get(filter, ["bedRooms"], []);
export const getFilterBathRooms = filter => _.get(filter, ["bathRooms"], []);
export const getFilterFloorLevels = filter =>
  _.get(filter, ["floorLevels"], []);
export const getFilterFurnishings = filter =>
  _.get(filter, ["furnishings"], []);
export const getFilterMinFloorSize = filter =>
  _.get(filter, ["minFloorSize"], null);
export const getFilterMaxFloorSize = filter =>
  _.get(filter, ["maxFloorSize"], null);
export const getFilterMinBuildYear = filter =>
  _.get(filter, ["minBuildYear"], null);
export const getFilterMaxBuildYear = filter =>
  _.get(filter, ["maxBuildYear"], null);
export const getFilterMinPricePerSize = filter =>
  _.get(filter, ["minPrice"], null);
export const getFilterMaxPricePerSize = filter =>
  _.get(filter, ["maxPrice"], null);
export const getFilterPetPolicy = filter => _.get(filter, ["petPolicys"], []);
export const getFilterKeyword = filter => _.get(filter, ["keyword"], "");
export const getFilterCategories = filter => _.get(filter, ["categories"], []);
export const getFilterLatitude = filter => _.get(filter, ["latitude"], "");
export const getFilterLongitude = filter => _.get(filter, ["longitude"], "");
export const getFilterRadius = filter => _.get(filter, ["radius"], "");


//UserProperty
export const getUserPropertyDetailCategoryCode = property => _.get(property, ["category", "code"], null);
export const getUserPropertyRequiredFields = property => _.get(property, ["requiredFields"], []);
export const getUserPropertyDetailTypeCode = property => _.get(property, ["type", "code"], null);
export const getUserPropertyDetailTypeName = property => _.get(property, ["type", "name"], null);
export const getUserPropertyDetailSaleObject = property => _.get(property, ["sale"], null);
export const getUserPropertyDetailRentObject = property => _.get(property, ["rent"], null);
export const getUserPropertyDetailID = property => _.get(property, ["id"], null);
export const getUserPropertyDetailSlug = property => _.get(property, ["slug"], null);
export const getUserPropertyDetailName = property => _.get(property, ["name"], null);
export const getUserPropertyDetailRentPriceValue = property => _.get(property, ["rent", "price", "value"], 0);
export const getUserPropertyDetailRentPriceText = property => _.get(property, ["rent", "price", "text"], 0);
export const getUserPropertyDetailContent = property => _.get(property, ["content"], "");
export const getUserPropertyDetailFurnishingCode = property => _.get(property, ["furnishing", "code"], null);
export const getUserPropertyDetailSalePriceValue = property => _.get(property, ["sale", "price", "value"], 0);
export const getUserPropertyDetailFloorLevelCode = property => _.get(property, ["floorLevel", "code"], null);
export const getUserPropertyDetailSizeFloorValue = property => _.get(property, ["size", "floor", "value"], "");
export const getUserPropertyDetailSizeLandValue = property => _.get(property, ["size", "land", "value"], "");
export const getUserPropertyDetailParkingPrimaryValue = property => _.get(property, ["parking", "primary", "value"], "");
export const getUserPropertyDetailTenureCode = property => _.get(property, ["tenure", "code"], null);
export const getUserPropertyDetailRoomBedValue = property => _.get(property, ["room", "bed", "value"], "");
export const getUserPropertyDetailRoomBathValue = property => _.get(property, ["room", "bath", "value"], "");
export const getUserPropertyDetailAmenityItems = property => _.get(property, ["amenity", "items"], []);
export const getUserPropertyDetailFeatureItems = property => _.get(property, ["feature", "items"], []);
export const getUserPropertyTitleTypeCode = property => _.get(property, ["titleType", "code"], "");
export const getUserPropertyBumiLotTypeCode = property => _.get(property, ["bumiLotType", "code"], "");
export const getPropertyPostUserListingItems = (state, id) =>
  _.get(state, ["userPropertyListingData", "items", id], null);
export const getPropertyUserDetailItems = (state, id) =>
  _.get(state, ["userPropertyListingDetail", "items", id], null);
export const getUserPropertyListingDetailLoading = state => _.get(state, ["userPropertyListingDetail", "loading"], false);



//====Details Form====
export const getUserPropertyPriceTypeCode = property => _.get(property, ["priceType", "code"], "");
export const getUserPropertyCondition = property => _.get(property, ["condition"], "");
export const getUserPropertyCeilingHeight = property => _.get(property, ["ceilingHeight"], "");
export const getUserPropertyFloorLoading = property => _.get(property, ["floorLoading"], "");
export const getUserPropertyParkingFees = property => _.get(property, ["parkingFees"], "");
export const getUserPropertyTotalCargoLift = property => _.get(property, ["totalCargoLift"], "");
export const getUserPropertyMaximumLiftCapacity = property => _.get(property, ["maximumLiftCapacity"], "");
export const getUserPropertyAirConditioning = property => _.get(property, ["airConditioning"], "");
export const getUserPropertyAirconOperationHours = property => _.get(property, ["airconOperationHours"], "");

export const getUserPropertyIsSale = property => !_.isEmpty(getUserPropertyDetailSaleObject(property));
export const getUserPropertyIsRent = property => !_.isEmpty(getUserPropertyDetailRentObject(property));
export const getUserPropertyDetailAttributeIsActive = property => _.get(property, ["attribute", "isActive"], false);

//=======Price Form===========
export const getUserPropertyDetailPurchasedPriceValue = property => _.get(property, ["purchase", "price", "price", "value"], "");
export const getUserPropertyDetailInvestmentType = property => _.get(property, ["purchase", "investment", "code"], "");
export const getUserPropertyDetailPurchasedYear = property => _.get(property, ["purchase", "year"], "");
export const getUserPropertyDetailLoanAmountOutstandingValue = property => _.get(property, ["purchase", "loan", "outstanding", "price", "value"], "");
export const getUserPropertyDetailRentalIncomeValue = property => _.get(property, ["purchase", "rental", "price", "value"], "");
export const getUserPropertyDetailInstallmentValue = property => _.get(property, ["purchase", "loan", "installment_amount", "price", "value"], "");
export const getUserPropertyDetailInterestRateValue = property => _.get(property, ["purchase", "loan", "installment_rate"], "");
export const getUserPropertyDetailFinancingBank = property => _.get(property, ["purchase", "loan", "bank"], "");
export const getUserPropertyDetailLoanDuration = property => _.get(property, ["purchase", "loan", "duration"], "");
export const getUserPropertyDetailRentFrom = property => _.get(property, ["purchase", "total_rental", "rentFrom"], "");
export const getUserPropertyDetailRentTo = property => _.get(property, ["purchase", "total_rental", "rentTo"], "");

//=======Summary Form============
export const getUserPropertyDetailPropertyType = property => _.get(property, ["category", "name"], "");
export const getUserPropertyDetailStatus = property => _.get(property, ["status", "name"], "");
export const getUserPropertyDetailBathRoomValue = property => _.get(property, ["room", "bath", "value"], "");
export const getUserPropertyDetailBathRoomText = property => _.get(property, ["room", "bath", "text"], "");
export const getUserPropertyDetailBedRoomValue = property => _.get(property, ["room", "bed", "value"], "");
export const getUserPropertyDetailBedRoomText = property => _.get(property, ["room", "bed", "text"], "");
export const getUserPropertyDetailFloorLevelValue = property => _.get(property, ["floorLevel", "name"], "");
export const getUserPropertyDetailPurchasePriceValue = property => _.get(property, ["purchase", "price", "price", "text"], "");
export const getUserPropertyDetailLandSizeValue = property => _.get(property, ["size", "land", "value"], "");
export const getUserPropertyDetailFloorSizeValue = property => _.get(property, ["size", "floor", "value"], "");
export const getUserPropertyDetailCarParkValue = property => _.get(property, ["parking", "primary", "value"], "");
export const getUserPropertyDetailFurnishingValue = property => _.get(property, ["furnishing", "name"], "");
export const getUserPropertyDetailTenureValue = property => _.get(property, ["tenure", "name"], "");
export const getUserPropertyDetailPurchaseYearValue = property => _.get(property, ["purchase", "year"], "");
export const getUserPropertyDetailImageListing = property => _.get(property, ["image", "large", "src"], "");
export const getUserPropertyDetailNameListing = property => _.get(property, ["name"], "");
export const getUserPropertyDetailAddressListing = property => _.get(property, ["address"], "");
export const getUserPropertyDetailDescriptionListing = property => _.get(property, ["content"], "");
export const getUserPropertyDetailAmenityListing = property => _.get(property, ["amenity", "items"], []);
export const getUserPropertyDetailBumiLotTypeValue = property => _.get(property, ["bumiLotType", "name"], []);
export const getUserPropertyDetailTitleTypeValue = property => _.get(property, ["titleType", "name"], []);
export const getUserPropertyDetailCreateAtValue = property => _.get(property, ["createAt", "relative"], []);
export const getUserPropertyDataFilter = (property,status) => _.get(property, ["userPropertyDataFilter", status], null);




//=====UserProperty Media=======//
export const getUserPropertyListImageDefaultStatus = state =>
  _.get(state, ["userPropertyListingImageDefault", "status"], null);
export const getUserPropertyListImageDefaultData = state =>
  _.get(state, ["userPropertyListingImageDefault", "image"], null);
export const getUserPropertyListImageDefaultLoading = state =>
  _.get(state, ["userPropertyListingImageDefault", "loading"], null);
export const getUserPropertyDetailVideoUrl = property => _.get(property, ["video", "url"], "");
export const getUserPropertyDetailTikTokUrl = property => _.get(property, ["tiktok", "url"], "");
export const getUserPropertyDetailImages = property => _.get(property, ["images"], []);
export const getUserPropertyListingImageLoading = state => _.get(state, ["userPropertyListingImage", "loading"], null);
export const getUserPropertyListingImage = state => _.get(state, ["userPropertyListingImage", "image"], null);
export const getUserPropertyDeleteListingImageData = state => _.get(state, ["deleteListingImage", "image"], null);
export const getUserPropertyDeleteListingImageLoading = state =>
  _.get(state, ["deleteListingImage", "loading"], null);

//=======UserProperty Location===========//
export const getUserPropertyDetailPropertyNames = property => _.get(property, ["name"], "");
export const getUserPropertyDetailTypeValue = property => _.get(property, ["type", "code"], "");
export const getUserPropertyDetailCategory = property => _.get(property, ["category", "name"], "");
export const getUserPropertyDetailState = property => _.get(property, ["state", "code"], "");
export const getUserPropertyDetailPostCode = property => _.get(property, ["postcode"], "");
export const getUserPropertyDetailAddress = property => _.get(property, ["address"], "");
export const getUserPropertyDetailCity = property => _.get(property, ["city", "name"], "");
export const getUserPropertyDetailCityObject = property => _.get(property, ["city"], null);
export const getUserPropertyShouldHideIcons = property => _.get(property, ["shouldHideIcons"], []);


//Property Listing
export const getUserPropertyListingID = property => _.get(property, ["id"], null);

//Property common
export const getUserPropertyCommonAmenityItems = common => _.get(common, ["amenity", "items"], []);
export const getUserPropertyCommonBuildStatusItems = common => _.get(common, ["buildStatus", "items"], []);
export const getUserPropertyCommonFloorLevelItems = common => _.get(common, ["floorLevel", "items"], []);
export const getUserPropertyCommonFurnishingItems = common => _.get(common, ["furnishing", "items"], []);
export const getUserPropertyCommonPetPolicyItems = common => _.get(common, ["petPolicy", "items"], []);
export const getUserPropertyCommonBedRoomItems = common => _.get(common, ["bedRoom", "items"], []);
export const getUserPropertyCommonBathRoomItems = common => _.get(common, ["bathRoom", "items"], []);
export const getUserPropertyCommonTenureItems = common => _.get(common, ["tenure", "items"], []);
export const getUserPropertyCommonPriceTypeItems = common => _.get(common, ["priceType", "items"], []);
export const getUserPropertyCommonPropertyTitleItems = common => _.get(common, ["propertyTitle", "items"], []);
export const getUserPropertyCommonBumiLotItems = common => _.get(common, ["bumiLot", "items"], []);
export const getPropertyPutUserListingStatus = state => _.get(state, ["putUserPropertyListing", "status"], false);
export const getPropertyPutUserListingLoading = state => _.get(state, ["putUserPropertyListing", "loading"]);
export const getUserPropertySearchStateLoading = state => _.get(state, ["postUserPropertySearchState", "loading"], false);
export const getUserPropertySearchStateItems = state => _.get(state, ["postUserPropertySearchState", "items"], []);
export const getUserPropertySearchCityItems = state => _.get(state, ["postUserPropertySearchCity", "items"], []);
export const getUserPropertyCommonInvestmentTypeItems = common => _.get(common, ["listingInvestmentType"], []);
export const getPropertyPutUserPriceListingStatus = state => _.get(state, ["putUserPropertyListingPrice", "status"], false);
export const getPropertyPutUserPriceListingLoading = state => _.get(state, ["putUserPropertyListingPrice", "loading"]);
export const getPropertyUserPropertyLoading = state => _.get(state,['userPropertyData','loading'],false)
export const getPropertyUserPropertyItems = state => _.get(state,['userPropertyData','items'],null)
export const getPropertyUserPropertyPagination = state => _.get(state,['userPropertyData','pagination'],null)
export const getPropertyPutStatusLoading =state => _.get(state,["putUserPropertyStatus",'loading'],false)
export const getPropertyPutStatusStatus =state => _.get(state,["putUserPropertyStatus",'status'],false)
export const getPropertyMyPropertyListingItems =state => _.get(state,["myPropertyListing",'items'],[])
export const getPropertyMyPropertyListingPagination =state => _.get(state,["myPropertyListing",'pagination'],null)
export const getPropertyMyPropertyListingLoading =state => _.get(state,["myPropertyListing",'loading'],false)
export const getPropertyStatisticLoading =state => _.get(state,["propertyStatistic",'loading'],false)
export const getPropertyStatisticDataItems =state => _.get(state,["propertyStatistic",'data','items'],null)
export const getPropertyStatisticDataTotal =state => _.get(state,["propertyStatistic",'data','total'],null)
export const getPropertyDataFilterStatusData =state => _.get(state,['userStatus','name'],"-")

//purchase price
export const getPropertyPurchaseLoanOutstandingPriceText  = property => _.get(property,['purchase','loan','outstanding','price','text'],"-")
export const getPropertyPurchasePricePriceText  = property => _.get(property,['purchase','price','price','text'],"-")
export const getPropertyPurchaseRentalPriceText  = property => _.get(property,['purchase','rental','price','text'],"-")
export const getPropertyPurchaseYear = property => _.get(property,['purchase','year'],"-")

//statistic total
// export const getPropertyStatisticDataCityNameItems = state => _.get(state, ["cityName"], []);
export const getPropertyStatisticDataTotalPurchasedPriceItems = state => _.get(state, ["userPropertyTotalPurchasedValue", "text"], []);
export const getPropertyStatisticDataTotalLoanAmountOutstandingItems = state => _.get(state, ["userPropertyTotalLoanOutstandingValue", "text"], []);
export const getPropertyStatisticDataTotalNettValueItems = state => _.get(state, ["userPropertyTotalNettValue", "text"], []);

//statistic data
export const getPropertyStatisticCityNameItems = state => _.get(state, ["cityName"], []);
export const getPropertyStatisticTotalPurchasedPriceItems = state => _.get(state, ["totalPurchasedPrice", "value"], []);
export const getPropertyStatisticLoanAmountOutstandingItems = state => _.get(state, ["totalLoanAmountOutstanding", "value"], []);
export const getPropertyStatisticNettItems = state => _.get(state, ["totalNettValue", "value"], []);
