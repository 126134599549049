import React, { PureComponent } from "react";
import styled from "styled-components";
import Parallax from "../../Parallax/Parallax";
import { withTheme } from "styled-components";
import Container from "@material-ui/core/Container";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { buttonUnstyledClasses } from "@mui/base";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CustomFont from "../../../components/CustomFont";
import Modal from "@material-ui/core/Modal";
import LoadingOverlay from "../../LoadingOverlay";
import EmptyItemBox from "../../EmptyItemBox";
import InfoIcon from "@mui/icons-material/Info";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import PlacesAutocomplete from "react-places-autocomplete";
import { Color as Colors, Color } from "../../../src/utils/Color";
import Skeleton from "@mui/material/Skeleton";
import Router from "next/router";
import Constants from "../../../src/constants";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  //background-color: rgba(0, 0, 0, 0.4);
  background-color: transparent;
  width: 100%;
  //padding: 10px 2px;
  border: none;
  border-radius: 4px 0 0 0;
  display: flex;
  justify-content: center;
  //width: 120px;
  margin-bottom: 20px;
  width: fit-content;
  padding: 5px 20px;
  transition: border-bottom 0.5s ease;
  border-bottom: 2px solid transparent;

  &:hover {
    //background-color: white;
    color: #295ffe;
  }

  &:focus {
    color: #fff;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    //background-color: white;
    //color: #295ffe;
    color: ${Colors.textWhite};
    border-bottom: 2px solid white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabRight = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  //background-color: rgba(0, 0, 0, 0.6);
  background: transparent;
  width: 100%;

  //padding: 10px 2px;
  border: none;
  border-radius: 0 4px 0 0;
  display: flex;
  justify-content: center;
  //width: 120px;
  margin-bottom: 20px;
  width: fit-content;
  padding: 5px 20px;
  transition: border-bottom 0.5s ease;
  border-bottom: 2px solid transparent;
  

  &:hover {
    //background-color: white;
    color: #295ffe;
  }

  &:focus {
    color: #fff;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    //background-color: white;
    //color: #295ffe;
    color: ${Colors.textWhite};
    border-bottom: 2px solid white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 300px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
const HeaderImageStyle = styled(Parallax)`
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  z-index: 8!important;
  position: relative;
`;

const BrandStyle = styled.div`
  color: #fff;
  text-align: center;
`;

const TitleStyle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
`;

const ContainerStyle = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  margin-top: 10rem;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const TextFieldContainer = styled(TextField)`
  background-color: white;

  & .MuiFilledInput-root {
    background-color: white;

    &:before {
      border-bottom: 0 !important;
    }
    &:hover {
      outline: 0 !important;
      background-color: white;
    }
    &:focus {
      outline: 0 !important;
    }
  }
  & .Mui-focused {
    outline: 0 !important;
    border-bottom: 0px;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
`;

const AutoCompleteContainer = styled.div`
  width: 32vw;
  //max-width: 90%;
  min-height: auto;
  height: auto;
  max-height: 300px;
  overflow: auto;
  background: white;
  position: absolute;
  border-radius: 0 0 4px 4px;
  top: 66px;
  //right: 7vw;
  text-align: left !important;
  
  padding-top: 15px;
`;

const SearchItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
`;

class DesktopBannerContainer extends PureComponent {
  constructor(props) {
    super(props);
    const debounceSearch = _.debounce(
        value => {
          this.startSearchProperty(value);
        },
        500,
        false
    );
    this.state = {
      anchorEL: null,
      buyVisible: false,
      tab: 0,
      rentVisible: false,
      focus: false,
      debounceSearchTrigger: value => debounceSearch(value),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focus !== this.props.focus) {
      if (!this.props.focus) {

        this.handleClose();
        this.handleRentClose();
      }
    }
  }

  handleClose = e => {
    const { handleClose } = this.props;
    this.setState({ buyVisible: false, anchorEL: null });
  };

  handleRentClose = e => {
    const { handleClose } = this.props;

    // this.setState({ rentVisible: false, anchorEL: null });
    this.setState({ rentVisible: false, anchorEL: null });
  };

  onFocus = e => {
    const { onClickFocus } = this.props;
    onClickFocus && onClickFocus();
    e.stopPropagation();
    this.setState({ buyVisible: true });
  };

  onRentFocus = e => {
    const { onClickFocus } = this.props;
    onClickFocus && onClickFocus();

    e.stopPropagation();

    this.setState({ focus: true }, () => this.setState({ rentVisible: true }));
  };

  onChangeBuyText = e => {
    const { onChangeBuyText } = this.props;

    onChangeBuyText && onChangeBuyText(e);
  };
  onChangeRentText = e => {
    const { onChangeRentText } = this.props;

    onChangeRentText && onChangeRentText(e);
  };

  onChangeTab = (e, value) => {
    this.setState({ tab: value, buyVisible: false });
  };

  onClose = e => {
    e.preventDefault();

    if (this.props.focus) {
      this.setState({ rentVisible: false, buyVisible: false, focus: false });
    }
  };
  onClickBuySearch = e => {
    const { onClickBuySearch } = this.props;

    onClickBuySearch && onClickBuySearch();
  };

  onClickRentSearch = e => {
    const { onClickRentSearch } = this.props;

    onClickRentSearch && onClickRentSearch();
  };

  onClickItem = (e, title) => {
    e.preventDefault();
    e.stopPropagation();

    const { onClickItem } = this.props;
    onClickItem && onClickItem(title);
    //this.setState({ buyVisible: true })
  };
  onClickRentItem = (e, title) => {
    e.preventDefault();
    e.stopPropagation();

    const { onClickRentItem } = this.props;
    onClickRentItem && onClickRentItem(title);
    //this.setState({ buyVisible: true })
  };

  getFormattedStateData = () => {
    const {commonStateData} =this.props
    const {rentSearchText, buySearchText} = this.state

    if(_.isEmpty(rentSearchText)){
      return commonStateData
    }else {
      const formattedSearchValue = _.replace(rentSearchText,'/[\\s,]/g').toLowerCase()
      return _.filter(commonStateData,item=>{

        const  formattedName = _.replace(item.name,'').toLowerCase()
        return _.includes(formattedName,formattedSearchValue)
      })
    }
  }

  getRoute = () => {
    return Constants.SECTION_PROPERTY_SALE;
  };

  onCloseAutoCompleted = e => {
    // e.stopPropagation();
    this.setState({ isShowAutoCompleted: false });
  };

  handleSelectLocal = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const radius = _.get(item, ["radius"], null);
    const coordinateStateLat = _.get(item, ["coordinates", "latitude"], null);
    const coordinateStateLng = _.get(item, ["coordinates", "longitude"], null);

    // const radiusCity = _.get(item, ["radius"], null);
    const coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
    const coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);

    if (ID === null) {
      this.setState({
        center: {
          lat: coordinateStateLat,
          lng: coordinateStateLng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    } else {
      this.setState({
        center: {
          lat: coordinatelat,
          lng: coordinatelng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    }

    var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocal(name);
  };
  // For rent
  handleSelectLocalRent = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const radius = _.get(item, ["radius"], null);
    const coordinateStateLat = _.get(item, ["coordinates", "latitude"], null);
    const coordinateStateLng = _.get(item, ["coordinates", "longitude"], null);

    // const radiusCity = _.get(item, ["radius"], null);
    const coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
    const coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);

    if (ID === null) {
      this.setState({
        center: {
          lat: coordinateStateLat,
          lng: coordinateStateLng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    } else {
      this.setState({
        center: {
          lat: coordinatelat,
          lng: coordinatelng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    }

    var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocalRent(name);
  };

  startSearchPropertyLocal = value => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataState } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const formatedValue = _.replace(_.toLower(value), /[\s,]/g, '');

    let matchedWords;

    const matchingItem = commonDataState.find(item => {
      const lowercasedName = _.replace(_.toLower(item.name), /[\s,]/g, '');

      return formatedValue.includes(lowercasedName);
    });


    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-sale/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };
  // For rent
  startSearchPropertyLocalRent = value => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataState } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const formatedValue = _.replace(_.toLower(value), /[\s,]/g, '');

    let matchedWords;

    const matchingItem = commonDataState.find(item => {
      const lowercasedName = _.replace(_.toLower(item.name), /[\s,]/g, '');

      return formatedValue.includes(lowercasedName);
    });


    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-rent/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };

  handleSelectLocalCity = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const nameState = _.get(item, ["state", "name"], null);
    const radius = _.get(item, ["radius"], null);

    const combinedName = nameState ? `${name}, ${nameState}` : name;


    let coordinatelat = _.get(item, ["coordinates", "latitude"], null);
    let coordinatelng = _.get(item, ["coordinates", "longitude"], null);

    if (coordinatelat === null || coordinatelng === null) {
      coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
      coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);
    }

    this.setState({
      center: {
        lat: coordinatelat,
        lng: coordinatelng
      },
      zoom: 14,
      radius: radius,
      isFirst: true
    });

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocalCity(name, item);

  };
  //For rent
  handleSelectLocalCityRent = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const nameState = _.get(item, ["state", "name"], null);
    const radius = _.get(item, ["radius"], null);

    const combinedName = nameState ? `${name}, ${nameState}` : name;


    let coordinatelat = _.get(item, ["coordinates", "latitude"], null);
    let coordinatelng = _.get(item, ["coordinates", "longitude"], null);

    if (coordinatelat === null || coordinatelng === null) {
      coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
      coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);
    }

    this.setState({
      center: {
        lat: coordinatelat,
        lng: coordinatelng
      },
      zoom: 14,
      radius: radius,
      isFirst: true
    });

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocalCityRent(name, item);

  };
  startSearchPropertyLocalCity = (value, item) => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataCity } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const nameState = _.get(item, ["state", "name"], null);
    const nameCity = _.get(item, ["name"], null);

    const formatedCity = _.replace(_.toLower(nameCity), /[\s,]/g, '');
    const formatedState = _.replace(_.toLower(nameState), /[\s,]/g, '');

    const matchingItem = commonDataCity.find(item => {
      const lowercasedName = _.replace(_.toLower(item.state.name), /[\s,]/g, '');
      const lowercasedNameCity = _.replace(_.toLower(item.name), /[\s,]/g, '');

      return formatedState.includes(lowercasedName) && formatedCity.includes(lowercasedNameCity);
    });

    const nameStateFormated = _.replace(_.toLower(nameState), /[\s,]/g, '-');
    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-sale/${nameStateFormated}/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };
  //For rent
  startSearchPropertyLocalCityRent = (value, item) => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataCity } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const nameState = _.get(item, ["state", "name"], null);
    const nameCity = _.get(item, ["name"], null);

    const formatedCity = _.replace(_.toLower(nameCity), /[\s,]/g, '');
    const formatedState = _.replace(_.toLower(nameState), /[\s,]/g, '');

    const matchingItem = commonDataCity.find(item => {
      const lowercasedName = _.replace(_.toLower(item.state.name), /[\s,]/g, '');
      const lowercasedNameCity = _.replace(_.toLower(item.name), /[\s,]/g, '');

      return formatedState.includes(lowercasedName) && formatedCity.includes(lowercasedNameCity);
    });

    const nameStateFormated = _.replace(_.toLower(nameState), /[\s,]/g, '-');
    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-rent/${nameStateFormated}/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };

  // handleKeyDown = e => {
  //   e.stopPropagation();
  //   const { debounceSearchTrigger, buySearchText } = this.state;
  //   if (e.key === "Enter") {
  //     this.setState(
  //         {
  //           isShowAutoCompleted: false
  //         },
  //         () => {
  //           debounceSearchTrigger(buySearchText);
  //         }
  //     );
  //   }
  // };

  startSearchProperty = value => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataCity } = this.props;
    const route = this.getRoute();
    // const { debounceSearchTrigger } = this.state;

    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }
    const lowercasedValue = value.replace(/\s/g, '-');

    var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

    const map = new google.maps.Map(document.getElementById("map"), {
      center: pyrmont,
      zoom: 15
    });

    const request = {
      input: value,
      fields: ["name", "geometry"],
      componentRestrictions: {
        country: 'MY'
      }
    };

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(request, this.callbackLocal);
    console.log("request", request)
    this.setState({ searchValue: value });

    Router.push({
      pathname: "/property-for-sale",
      query: { q: lowercasedValue }
    });
  };

  callbackLocal = (results, status) => {

    if (status === google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
      const place = results[0];

      const placeId = place.place_id;

      const detailsRequest = {
        placeId: placeId,
        fields: ["geometry"]
      };

      const detailsService = new google.maps.places.PlacesService(map);
      detailsService.getDetails(detailsRequest, (details, detailsStatus) => {
        // console.log("detailsRequest", detailsRequest)
        if (detailsStatus === google.maps.places.PlacesServiceStatus.OK) {

          const location = details.geometry.location;

          this.setState({
            center: {
              lat: location.lat(),
              lng: location.lng()
            },
            zoom: 14,
            isFirst: true
          });
        } else {
          console.log("Error fetching place details:", detailsStatus);
        }
      });
    } else {
      this.setState({
        center: {
          lat: 4.210484,
          lng: 101.975769
        },
        zoom: 8,
        isFirst: true
      });
    }
  };

  render() {
    const {
      buySearchText,
      rentSearchText,
      searchSuggestionItems,
      searchSuggestionLoading,
      searchRentSuggestionItems,
      gmapsLoaded,
      commonDataState,
      commonDataCity,
      suggestionsLocal,
      suggestionsCity
    } = this.props;
    const { anchorEL, buyVisible, tab, rentVisible } = this.state;
    const formatedStateData = this.getFormattedStateData()
    return (
      <HeaderImageStyle
        // style={{ height: "55vh", marginTop: "3rem", zIndex: 999 }}
        style={{ height: "300px", marginTop: "4rem", zIndex: 999 }}
        responsive
        // image="/home-banner.png"
        // image="/images/living-room-interior.png"
        image="/images/main-top-banner.jpg"
      >
        <Container>
          <ContainerStyle>
            <GridContainer style={{ justifyContent: "center" }}>
              <GridItem style={{ width: "100%" }}>
                <BrandStyle>
                  {/*<CustomFont*/}
                  {/*  size="xalarge"*/}
                  {/*  variant="h1"*/}
                  {/*  styles={{*/}
                  {/*    maxWidth: "60vw",*/}
                  {/*    fontWeight: "600",*/}
                  {/*    textShadow: "0px 3px 5px rgba(0, 0, 0, 0.64)",*/}
                  {/*    display: "inline-block",*/}
                  {/*    position: "relative",*/}
                  {/*    color: Colors.textWhite*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Find Your Next Home In Minutes.*/}
                  {/*</CustomFont>*/}
                  <div style={{ visibility: "hidden", marginTop: "5rem" }}></div>
                  <Box sx={{ width: "100%", marginTop: "2rem" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <TabsUnstyled
                        onChange={this.onChangeTab}
                        defaultValue={0}
                        value={tab}
                        style={{
                          width: "43vw",
                          background: "rgba(0,0,0,.5)",
                          padding: "30px 0",
                          borderRadius: "6px"
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <TabsList>
                            <Tab style={{ fontSize: "1.3rem"}}>Buy</Tab>
                            <TabRight style={{ fontSize: "1.3rem" }}>
                              Rent
                            </TabRight>
                          </TabsList>
                        </div>
                        <TabPanel value={0} index={0}>
                          {/*{gmapsLoaded && commonDataState ? (*/}
                           {gmapsLoaded ? (
                            <PlacesAutocomplete
                              value={buySearchText}
                              onChange={this.onChangeBuyText}
                              ref={refObj => {
                                refObj
                                  ? (refObj.handleInputOnBlur = () => {})
                                  : false;
                              }}
                              searchOptions={{
                                componentRestrictions: { country: "my" },
                                fields: ["geometry"],
                                types: ["geocode", "establishment"]
                              }}
                              // onSelect={this.handleSelect}
                              style={{ position: "relative" }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                              }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "center"
                                    }}
                                    onClick={this.onFocus}
                                  >
                                    <TextFieldContainer
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder="Search for address, city or zip code."
                                      variant="filled"
                                      disableunderline={"true"}
                                      autoComplete="off"
                                      onChange={this.onChangeBuyText}
                                      //  onBlur={this.handleClose}
                                      // fullWidth={200}
                                      value={buySearchText}
                                      inputProps={{
                                        style: { backgroundColor: "white" }
                                      }}
                                      {...getInputProps({
                                        placeholder:
                                          "Enter an address, city or ZIP code",
                                        className: "location-search-input"
                                      })}
                                      style={{
                                        backgroundColor: "white",
                                        width: "32vw",
                                        // borderRadius: buyVisible ? "0 4px 0 0" : "4px 4px 4px 4px",
                                        borderRadius: buyVisible
                                          ? "4px 0 0 0"
                                          : "4px 0px 0px 4px",
                                        padding: "5px 15px"
                                        // paddingTop: "20px",
                                        // paddingBottom: "20px",
                                        // maxWidth: "90%"
                                      }}
                                      onKeyDown={this.handleKeyDown}
                                      // InputProps={{
                                      //   endAdornment: (
                                      //     <InputAdornment style={{ backgroundColor: "white" }} position="end">
                                      //       <Button onClick={this.onClickBuySearch} variant="contained">
                                      //         <SearchIcon />
                                      //       </Button>
                                      //     </InputAdornment>
                                      //   )
                                      // }}
                                    />

                                    {buyVisible ? (
                                      // <div style={{ justifyContent: "center", display: "flex" }}>
                                      <AutoCompleteContainer>
                                        {/*/!*===Local Data===*!/*/}
                                        {!_.isEmpty(suggestionsLocal) || !_.isEmpty(suggestionsCity) ? (
                                            <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>
                                        ) : (
                                            false
                                        )}
                                        {/*{!_.isEmpty(suggestions) && (*/}
                                        {/*    _.some([...commonDataState, ...commonDataCity], item => item.name.toLowerCase().includes(buySearchText.toLowerCase()))*/}
                                        {/*) ? (*/}
                                        {/*    <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>*/}
                                        {/*) : (*/}
                                        {/*    false*/}
                                        {/*)}*/}
                                        {!_.isEmpty(suggestions) ? (
                                            // _.map(commonDataState.filter(item => item.name.toLowerCase().includes(buySearchText.toLowerCase())), (item, index) => {
                                            _.map(suggestionsLocal, (item, index) => {
                                              const nameState = _.get(item, "name", "");
                                              return (
                                                  <SearchItemContainer
                                                      {...getSuggestionItemProps(item)}
                                                      onClick={e => this.handleSelectLocal(e, item)}
                                                  >
                                                    <CustomFont hover>{nameState}</CustomFont>
                                                  </SearchItemContainer>
                                              );
                                            })
                                        ) : (
                                            false
                                        )}
                                        {!_.isEmpty(suggestions) ? (
                                            // _.map(commonDataCity.filter(item => item.name.toLowerCase().includes(buySearchText.toLowerCase())).slice(0, 5), (item, index) => {
                                            _.map(suggestionsCity, (item, index) => {
                                              const nameCity = _.get(item, "name", "");
                                              const nameState = _.get(item, ["state", "name"], "");
                                              return (
                                                  <SearchItemContainer
                                                      {...getSuggestionItemProps(item)}
                                                      onClick={e => this.handleSelectLocalCity(e, item)}
                                                  >
                                                    <CustomFont hover>{nameCity}, {nameState}</CustomFont>
                                                  </SearchItemContainer>
                                              );
                                            })
                                        ) :  (
                                            false
                                        )}
                                        {/*// Google map*/}
                                        {!_.isEmpty(suggestions) ? (
                                            <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Additional Locations</CustomFont>
                                        ) : (
                                            false
                                        )}
                                        {!_.isEmpty(suggestions) ? (
                                          _.map(suggestions, item => {
                                            const description = _.get(
                                              item,
                                              "description",
                                              ""
                                            );
                                            return (
                                              <SearchItemContainer
                                                {...getSuggestionItemProps(
                                                  item
                                                )}
                                                onClick={e =>
                                                  this.onClickItem(e, item)
                                                }
                                              >
                                                <CustomFont hover>
                                                  {description}
                                                </CustomFont>
                                              </SearchItemContainer>
                                            );
                                          })
                                        ) : _.isEmpty(suggestions) &&
                                          !loading ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flex: 1,
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              height: "200px"
                                            }}
                                          >
                                            <InfoIcon
                                              style={{ color: "#2c2c2c" }}
                                            />
                                            <CustomFont
                                              styles={{ marginTop: 5 }}
                                            >
                                              {_.isEmpty(buySearchText)
                                                ? `Start typing keyword`
                                                : `No related keyword`}
                                            </CustomFont>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              flex: 1,
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              height: "200px"
                                            }}
                                          >
                                            <LoadingOverlay
                                              height={30}
                                              width={30}
                                            />
                                          </div>
                                        )}
                                      </AutoCompleteContainer>
                                    ) : (
                                      // </div>
                                      false
                                    )}
                                  </div>
                                  <Button
                                    onClick={this.onClickBuySearch}
                                    variant="contained"
                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                    aria-label="SearchIcon"
                                  >
                                    <SearchIcon />
                                  </Button>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                padding: "0px 25px",
                                justifyContent: "center"
                              }}
                            >
                              <Skeleton
                                variant="rounded"
                                // width={300}
                                height={66}
                                animation="wave"
                                sx={{
                                  bgcolor: "white",
                                  flex: "1",
                                  color: Colors.iconColor,
                                  paddingLeft: "30px",
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "1rem",
                                  minWidth: "32vw"
                                }}
                              >
                                Loading.....
                              </Skeleton>

                              <Skeleton
                                variant="rounded"
                                height={66}
                                animation="wave"
                                sx={{
                                  bgcolor: Colors.primaryColor,
                                  flex: "0 0 64px"
                                }}
                              />
                            </Box>
                          )}
                        </TabPanel>
                        {/*=====Rent section===*/}
                        <TabPanel value={1} index={1}>
                          {gmapsLoaded && commonDataState ? (
                            <PlacesAutocomplete
                              value={rentSearchText}
                              onChange={this.onChangeRentText}
                              ref={refObj => {
                                refObj
                                  ? (refObj.handleInputOnBlur = () => {})
                                  : false;
                              }}
                              searchOptions={{
                                componentRestrictions: { country: "my" },
                                fields: ["geometry"],
                                types: ["geocode", "establishment"]
                              }}
                              // onSelect={this.handleSelect}
                              style={{ position: "relative" }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                              }) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    onClick={this.onRentFocus}
                                  >
                                    <TextFieldContainer
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder="Search for address, city or zip code."
                                      variant="filled"
                                      autoComplete="off"
                                      //onFocus={this.onRentFocus}
                                      onChange={this.onChangeRentText}
                                      // onBlur={this.handleRentClose}
                                      // fullWidth={200}
                                      value={rentSearchText}
                                      inputProps={{
                                        style: { backgroundColor: "white" }
                                      }}
                                      {...getInputProps({
                                        placeholder:
                                          "Enter an address, city or ZIP code",
                                        className: "location-search-input"
                                      })}
                                      style={{
                                        backgroundColor: "white",
                                        width: "32vw",
                                        // borderRadius: rentVisible ? "0 4px 0 0" : "4px 4px 4px 4px",
                                        borderRadius: rentVisible
                                          ? "4px 0 0 0"
                                          : "4px 0px 0px 4px",
                                        padding: "5px 15px"
                                        // paddingTop: "20px",
                                        // paddingBottom: "20px",
                                        // maxWidth: "90%"
                                      }}
                                      onKeyDown={this.handleKeyDown}
                                        // InputProps={{
                                      //   endAdornment: (
                                      //     <InputAdornment style={{ backgroundColor: "white" }} position="end">
                                      //       <Button onClick={this.onClickRentSearch} variant="contained">
                                      //         <SearchIcon />
                                      //       </Button>
                                      //     </InputAdornment>
                                      //   ),
                                      //   classes: TextFieldContainer
                                      // }}
                                    />
                                    {rentVisible ? (
                                      // <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                                      <AutoCompleteContainer>
                                        {/*/!*===Local Data===*!/*/}
                                        {/*{!_.isEmpty(suggestions) ? (*/}
                                        {/*    <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>*/}
                                        {/*) : (*/}
                                        {/*    false*/}
                                        {/*)}*/}
                                        {!_.isEmpty(suggestions) && (
                                            _.some([...commonDataState, ...commonDataCity], item => item.name.toLowerCase().includes(rentSearchText.toLowerCase()))
                                        ) ? (
                                            <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>
                                        ) : (
                                            false
                                        )}
                                        {!_.isEmpty(suggestions) ? (
                                            _.map(commonDataState.filter(item => item.name.toLowerCase().includes(rentSearchText.toLowerCase())), (item, index) => {
                                              const nameState = _.get(item, "name", "");
                                              return (
                                                  <SearchItemContainer
                                                      {...getSuggestionItemProps(item)}
                                                      onClick={e => this.handleSelectLocalRent(e, item)}
                                                  >
                                                    <CustomFont hover>{nameState}</CustomFont>
                                                  </SearchItemContainer>
                                              );
                                            })
                                        ) :  (
                                            false
                                        )}
                                        {!_.isEmpty(suggestions) ? (
                                            _.map(commonDataCity.filter(item => item.name.toLowerCase().includes(rentSearchText.toLowerCase())).slice(0, 5), (item, index) => {
                                              const nameCity = _.get(item, "name", "");
                                              const nameState = _.get(item, ["state", "name"], "");
                                              return (
                                                  <SearchItemContainer
                                                      {...getSuggestionItemProps(item)}
                                                      onClick={e => this.handleSelectLocalCityRent(e, item)}
                                                  >
                                                    <CustomFont hover>{nameCity}, {nameState}</CustomFont>
                                                  </SearchItemContainer>
                                              );
                                            })
                                        ) :  (
                                            false
                                        )}
                                        {/*// Google map*/}
                                        {!_.isEmpty(suggestions) ? (
                                            <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Additional Locations</CustomFont>
                                        ) : (
                                            false
                                        )}
                                        {!_.isEmpty(suggestions) ? (
                                          _.map(suggestions, item => {
                                            const description = _.get(
                                              item,
                                              "description",
                                              ""
                                            );
                                            return (
                                              <SearchItemContainer
                                                {...getSuggestionItemProps(
                                                  item
                                                )}
                                                onClick={e =>
                                                  this.onClickRentItem(e, item)
                                                }
                                              >
                                                <CustomFont hover>
                                                  {description}
                                                </CustomFont>
                                              </SearchItemContainer>
                                            );
                                          })
                                        ) : _.isEmpty(suggestions) &&
                                          !loading ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              flex: 1,
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              height: "200px"
                                            }}
                                          >
                                            <InfoIcon
                                              style={{ color: "#2c2c2c" }}
                                            />
                                            <CustomFont
                                              styles={{ marginTop: 5 }}
                                            >
                                              {_.isEmpty(rentSearchText)
                                                ? `Start typing keyword`
                                                : `No related keyword`}
                                            </CustomFont>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              flex: 1,
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              height: "200px"
                                            }}
                                          >
                                            <LoadingOverlay />
                                          </div>
                                        )}
                                      </AutoCompleteContainer>
                                    ) : (
                                      // </div>
                                      false
                                    )}
                                  </div>
                                  <Button
                                    onClick={this.onClickRentSearch}
                                    variant="contained"
                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                    aria-label="SearchIcon"
                                  >
                                    <SearchIcon />
                                  </Button>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                padding: "0px 25px",
                                justifyContent: "center"

                              }}
                            >
                              <Skeleton
                                variant="rounded"
                                // width={300}
                                height={66}
                                animation="wave"
                                sx={{
                                  bgcolor: "white",
                                  flex: "1",
                                  color: Colors.iconColor,
                                  paddingLeft: "30px",
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "1rem",
                                  minWidth: "32vw"
                                }}
                              >
                                Loading.....
                              </Skeleton>

                              <Skeleton
                                variant="rounded"
                                height={66}
                                animation="wave"
                                sx={{
                                  bgcolor: Colors.primaryColor,
                                  flex: "0 0 64px"
                                }}
                              />
                            </Box>
                          )}
                        </TabPanel>
                      </TabsUnstyled>
                    </Box>
                  </Box>
                </BrandStyle>
              </GridItem>
            </GridContainer>
          </ContainerStyle>
        </Container>
      </HeaderImageStyle>
    );
  }
}

export default withTheme(DesktopBannerContainer);
